import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { FaArrowRight } from 'react-icons/fa'

import "../scss/youdata.scss"
import "../scss/companies.scss"

import HeaderLogoImg from "../assets/img/compsol/data_1.svg"
import HeaderImg from "../assets/img/youData/img1.png"
import Img2 from "../assets/img/youData/img2.png"
import Blackbox from "../assets/img/youData/Icon1.png"
import AI from "../assets/img/youData/Icon2.png"
import API from "../assets/img/youData/Icon3.png"
import Icon4 from "../assets/img/youData/Icon4.png"
import Icon5 from "../assets/img/youData/Icon5.png"
import Icon6 from "../assets/img/youData/Icon5.png"
import Icon7 from "../assets/img/youData/Icon7.png"
import Icon8 from "../assets/img/youData/Icon8.png"
import Icon9 from "../assets/img/youData/Icon9.png"
import Img4 from "../assets/img/youData/img3.png"
import TickIcon from "../assets/img/youData/tick.svg"
import SingleForm from "../components/singleForm"

const YouDataPage = () => {
    return (
        <div>
            <SEO title="Youdata" keywords={[`youpal`, `youpal group`, `youData`]} />
            <div className="youMain youDataMain">
                <section className="container sectionHeader">
                    <div className="col youDataHeader">
                        <HeaderLogoImg className="headerLogo" />
                        <div className="internalMainHeader bugP">
                            <h2 className="internalNarrowCol">Data is central to every story.</h2>
                        </div>
                        <p className="internalNarrowCol whiteContent">
                        How well you keep and use information will determine your success. While you are the authors of your story, we will be the bookkeepers of your data. 
                        </p>
                    </div>
                </section>

                <section className="container internalSubheader">
                    <div className="col">
                        <span className="internalNarrowCol internalSmallHeader">Features</span>
                        <h3 className="internalNarrowCol">
                            Solutions for businesses 
                        </h3>
                        <p className="internalNarrowCol">
                        We secure your data and give you the right tools and features so that data is your core-value driver, constantly advancing to the new level.
                        </p>
                    </div>
                </section>

                <section className="container contactsSection">
                    <div className="row">
                        <div className="col">
                            <div><img src={Blackbox} /></div>
                            <h4>Data Lakes</h4>
                            <p>
                            With Data Lakes, we create a data-secure eco-system for you.
                            </p>
                        </div>
                        <div className="col">
                            <div className="imageSpace"><img src={AI} /></div>
                            <h4>Machine Learning & AI</h4>
                            <p>
                            We generate structured and clean information from your data, then train machines and create algorithms applicable to that data.
                            </p>
                        </div>
                        <div className="col">
                            <div className="imageSpace"><img src={API} /></div>
                            <h4>API</h4>
                            <p>
                            How do you get the most out of your data? We get you the right database structure and appropriate API thinking for every data source.                           
                            </p>
                        </div>
                    </div>
                </section>

                <section className="container internalSection internalAnalytics">
                    <div className="row TwoCol imageAlign">
                        <div className="col">
                            <img className="sideImg" src={Img2} />
                        </div>
                        <div className="col">
                            <div className="col sideContent">
                                <div className="internalSmallSecondaryHeader text-left mb-1 mb-md-5">Analytics</div>
                                <h3 className="text-left">Youdata is in your life</h3>
                                <p className="text-left mt-1 mt-md-5">
                                Data is powering every facet of our modern society. With our increased capacity to collect and analyse data, we are able to integrate information and transform our everyday lives.
                                </p>
                                <div className="buttonArea2 text-left mt-1 mt-md-5 buttonAlign">
                                    <a href="https://youdata.se" target="_blank">
                                        <button type="button" className="btn btn-primary px-4">
                                            <span className="mr-5">Check it out</span>
                                            <FaArrowRight style={{ fill: '#1a1a1a' }} />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container exploreSection">
                    <div className="row">
                        <div className="col textAlign">
                            <div className="internalSmallSecondaryHeader">Features</div>
                            <h3 className="mt-2">Youdata is all around you</h3>
                            <div className="row">
                                <div className="exploreFeature col-4">
                                    <div className="row">
                                        <div><img src={Icon4} /></div>
                                        <div className="col sideContent">
                                            <h5>A global database</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature col-4">
                                    <div className="row">
                                        <div><img src={Icon5} /></div>
                                        <div className="col sideContent">
                                            <h5>Data screening</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature col-4">
                                    <div className="row">
                                        <div><img src={Icon7} /></div>
                                        <div className="col sideContent">
                                            <h5>AI-powered machine learning technology</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature col-4">
                                    <div className="row">
                                        <div><img src={Icon8} /></div>
                                        <div className="col sideContent">
                                            <h5>Data Intuitive visualisations</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature col-4">
                                    <div className="row">
                                        <div><img src={Icon9} /></div>
                                        <div className="col sideContent">
                                            <h5>User-friendly platform</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="exploreFeature col-4">
                                    <div className="row">
                                        <div><img src={Icon6} /></div>
                                        <div className="col sideContent">
                                            <h5>Unlimited list of industries</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container internalSection sectAlign">
                    <div className="row TwoCol mx-md-5 mx-0">
                        <div className="col internalCollaborateContent leftSide">
                            <div className="col sideContent">
                                <div className="internalSmallSecondaryHeader">Collaborate</div>
                                    <h3>Why Your data is important to Youdata?</h3>
                                <p>
                                We create data with every step we take. Data driven decisions can help us improve our daily lives.
                                </p>
                                <div className="row collaborationList">
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />Data Lakes</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />AI integration</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />Secure environment</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />API</div>
                                    <div className="col-6"><img className="pl-md-0" src={TickIcon} />KPI tracking</div>
                                </div>
                            </div>
                        </div>
                        <div className="col rightSide">
                            <img className="sideImg" src={Img4} />
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div className="row contactBox">
                        <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=23" selector="contact-us-mautic-company-form" />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default YouDataPage
